import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/customer-support/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/support-overview',
      name: 'support-overview',
      component: () => import('@/views/customer-support/SupportOverview.vue'),
      meta: {
        pageTitle: 'Support Overview',
        breadcrumb: [
          {
            text: 'Support Overview',
            active: true,
          },
        ],
      },
    },
    {
      path: '/open-offers',
      name: 'open-offers',
      component: () => import('@/views/customer-support/OpenOffers.vue'),
      meta: {
        pageTitle: 'Open Offers',
        breadcrumb: [
          {
            text: 'Open Offers',
            active: true,
          },
        ],
      },
    },
    {
      path: '/top-customers',
      name: 'top-customers',
      component: () => import('@/views/customer-support/TopCustomers.vue'),
      meta: {
        pageTitle: 'Top Customers',
        breadcrumb: [
          {
            text: 'Top Customers',
            active: true,
          },
        ],
      },
    },
    {
      path: '/products',
      name: 'products',
      component: () => import('@/views/customer-support/Products.vue'),
      meta: {
        pageTitle: 'Products',
        breadcrumb: [
          {
            text: 'Products',
            active: true,
          },
        ],
      },
    },
    {
      path: '/calculation-special-items',
      name: 'calculation-special-items',
      component: () => import('@/views/customer-support/CalculationSpecialItems.vue'),
      meta: {
        pageTitle: 'Calculation Special Items',
        breadcrumb: [
          {
            text: 'Calculation Special Items',
            active: true,
          },
        ],
      },
    },
    {
      path: '/conversion-rate',
      name: 'conversion-rate',
      component: () => import('@/views/customer-support/ConversionRate.vue'),
      meta: {
        pageTitle: 'Conversion Rate',
        breadcrumb: [
          {
            text: 'Conversion Rate',
            active: true,
          },
        ],
      },
    },
    {
      path: '/avg-delivery-time',
      name: 'avg-delivery-time',
      component: () => import('@/views/customer-support/AvgDeliveryTime.vue'),
      meta: {
        pageTitle: 'Avg Delivery Time',
        breadcrumb: [
          {
            text: 'Avg Delivery Time',
            active: true,
          },
        ],
      },
    },
    {
      path: '/phone-statistics',
      name: 'phone-statistics',
      component: () => import('@/views/customer-support/PhoneStatistics.vue'),
      meta: {
        pageTitle: 'Phone Statistics',
        breadcrumb: [
          {
            text: 'Phone Statistics',
            active: true,
          },
        ],
      },
    },
    {
      path: '/email-statistics',
      name: 'email-statistics',
      component: () => import('@/views/customer-support/EmailStatistics.vue'),
      meta: {
        pageTitle: 'Email Statistics',
        breadcrumb: [
          {
            text: 'Email Statistics',
            active: true,
          },
        ],
      },
    },
    {
      path: '/delayed-unconfirmed-orders',
      name: 'delayed-unconfirmed-orders',
      component: () => import('@/views/purchase-department/DelayedUnconfirmedOrders.vue'),
      meta: {
        pageTitle: 'Delayed/Unconfirmed Orders',
        breadcrumb: [
          {
            text: 'Delayed/Unconfirmed Orders',
            active: true,
          },
        ],
      },
    },
    {
      path: '/open-invoices',
      name: 'open-invoices',
      component: () => import('@/views/purchase-department/OpenInvoices.vue'),
      meta: {
        pageTitle: 'Open Invoices',
        breadcrumb: [
          {
            text: 'Open Invoices',
            active: true,
          },
        ],
      },
    },
    {
      path: '/open-suppliers-orders',
      name: 'open-suppliers-orders',
      component: () => import('@/views/purchase-department/OpenSuppliersOrders.vue'),
      meta: {
        pageTitle: 'Open Suppliers Orders',
        breadcrumb: [
          {
            text: 'Open Suppliers Orders',
            active: true,
          },
        ],
      },
    },
    {
      path: '/not-ordered-products',
      name: 'not-ordered-products',
      component: () => import('@/views/purchase-department/NotOrderedProducts.vue'),
      meta: {
        pageTitle: 'Not Ordered Products',
        breadcrumb: [
          {
            text: 'Not Ordered Products',
            active: true,
          },
        ],
      },
    },
    {
      path: '/suppliers-statistics',
      name: 'suppliers-statistics',
      component: () => import('@/views/purchase-department/SuppliersStatistics.vue'),
      meta: {
        pageTitle: 'Suppliers Statistics',
        breadcrumb: [
          {
            text: 'Suppliers Statistics',
            active: true,
          },
        ],
      },
    },
    {
      path: '/category-development',
      name: 'category-development',
      component: () => import('@/views/product-management/CategoryDevelopment.vue'),
      meta: {
        pageTitle: 'Category Development',
        breadcrumb: [
          {
            text: 'Category Development',
            active: true,
          },
        ],
      },
    },
    {
      path: '/category-statistics',
      name: 'category-statistics',
      component: () => import('@/views/product-management/CategoryStatistics.vue'),
      meta: {
        pageTitle: 'Category Statistics',
        breadcrumb: [
          {
            text: 'Category Statistics',
            active: true,
          },
        ],
      },
    },
    {
      path: '/brands-statistics',
      name: 'brands-statistics',
      component: () => import('@/views/product-management/BrandsStatistics.vue'),
      meta: {
        pageTitle: 'Brands Statistics',
        breadcrumb: [
          {
            text: 'Brands Statistics',
            active: true,
          },
        ],
      },
    },
    {
      path: '/products-attributes',
      name: 'products-attributes',
      component: () => import('@/views/product-management/ProductsAttributes.vue'),
      meta: {
        pageTitle: 'Products Attributes',
        breadcrumb: [
          {
            text: 'Products Attributes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/product-abc',
      name: 'product-abc',
      component: () => import('@/views/product-management/ProductABC.vue'),
      meta: {
        pageTitle: 'Product ABC Analysis',
        breadcrumb: [
          {
            text: 'Product ABC Analysis',
            active: true,
          },
        ],
      },
    },
    {
      path: '/not-sold-products',
      name: 'not-sold-products',
      component: () => import('@/views/product-management/NotSoldProducts.vue'),
      meta: {
        pageTitle: 'Not Sold Products',
        breadcrumb: [
          {
            text: 'Not Sold Products',
            active: true,
          },
        ],
      },
    },
    {
      path: '/active-inactive-products',
      name: 'active-inactive-products',
      component: () => import('@/views/product-management/ActiveInactiveProducts.vue'),
      meta: {
        pageTitle: 'Active/Inactive Products',
        breadcrumb: [
          {
            text: 'Active/Inactive Products',
            active: true,
          },
        ],
      },
    },
    {
      path: '/new-articles-hh',
      name: 'new-articles-hh',
      beforeEnter() {
        window.open('https://docs.google.com/spreadsheets/d/1R1CPUy0VqdAxI8mKHkvFvnklQO3hPrxrztE9utVqWxg/edit#gid=0', '_blank');
      },
    },
    {
      path: '/all-scraped-items',
      name: 'all-scraped-items',
      beforeEnter() {
        window.open('https://docs.google.com/spreadsheets/d/10knGlv8zimiRj0OVdZ5pbvhF3EuA-NFn8Hqt7CwCcNE/edit#gid=245173986', '_blank');
      },
    },
    {
      path: '/pricing-tool',
      name: 'pricing-tool',
      beforeEnter() {
        window.open('https://docs.google.com/spreadsheets/d/1n9bsm6MyAq5fHj8nJ31gbe9uWZEUCAYuGfLVtZ6s7QY/edit#gid=1348256019', '_blank');
      },
    },
    {
      path: '/analytics',
      name: 'analytics',
      component: () => import('@/views/controlling/Analytics.vue'),
      meta: {
        pageTitle: 'Analytics',
        breadcrumb: [
          {
            text: 'Analytics',
            active: true,
          },
        ],
      },
    },
    {
      path: '/orders-analytics',
      name: 'orders-analytics',
      component: () => import('@/views/controlling/OrdersAnalytics.vue'),
      meta: {
        pageTitle: 'Orders Analytics',
        breadcrumb: [
          {
            text: 'Orders Analytics',
            active: true,
          },
        ],
      },
    },
    {
      path: '/customers-analytics',
      name: 'customers-analytics',
      component: () => import('@/views/controlling/CustomersAnalytics.vue'),
      meta: {
        pageTitle: 'Customers Analytics',
        breadcrumb: [
          {
            text: 'Customers Analytics',
            active: true,
          },
        ],
      },
    },
    {
      path: '/finance-report',
      name: 'finance-report',
      component: () => import('@/views/controlling/FinanceReport.vue'),
      meta: {
        pageTitle: 'Finance Report',
        breadcrumb: [
          {
            text: 'Finance Report',
            active: true,
          },
        ],
      },
    },
    {
      path: '/month-report',
      name: 'month-report',
      component: () => import('@/views/controlling/MonthReport.vue'),
      meta: {
        pageTitle: 'Month Report',
        breadcrumb: [
          {
            text: 'Month Report',
            active: true,
          },
        ],
      },
    },
    {
      path: '/stock-value',
      name: 'stock-value',
      component: () => import('@/views/controlling/StockValue.vue'),
      meta: {
        pageTitle: 'Stock Value',
        breadcrumb: [
          {
            text: 'Stock Value',
            active: true,
          },
        ],
      },
    },
    {
      path: '/liquidity',
      name: 'liquidity',
      component: () => import('@/views/controlling/Liquidity.vue'),
      meta: {
        pageTitle: 'Liquidity',
        breadcrumb: [
          {
            text: 'Liquidity',
            active: true,
          },
        ],
      },
    },
    {
      path: '/revenue-margin',
      name: 'revenue-margin',
      component: () => import('@/views/controlling/RevenueMargin.vue'),
      meta: {
        pageTitle: 'Revenue Margin',
        breadcrumb: [
          {
            text: 'Revenue Margin',
            active: true,
          },
        ],
      },
    },
    {
      path: '/price-calculation',
      name: 'price-calculation',
      component: () => import('@/views/controlling/PriceCalculation.vue'),
      meta: {
        pageTitle: 'Price Calculation',
        breadcrumb: [
          {
            text: 'Price Calculation',
            active: true,
          },
        ],
      },
    },
    {
      path: '/orders',
      name: 'orders',
      component: () => import('@/views/controlling/Orders.vue'),
      meta: {
        pageTitle: 'Orders',
        breadcrumb: [
          {
            text: 'Orders',
            active: true,
          },
        ],
      },
    },
    {
      path: '/freight-charges',
      name: 'freight-charges',
      component: () => import('@/views/controlling/FreightCharges.vue'),
      meta: {
        pageTitle: 'Freight Charges',
        breadcrumb: [
          {
            text: 'Freight Charges',
            active: true,
          },
        ],
      },
    },
    {
      path: '/suppliers-orders',
      name: 'suppliers-orders',
      component: () => import('@/views/controlling/SuppliersOrders.vue'),
      meta: {
        pageTitle: 'Suppliers Orders',
        breadcrumb: [
          {
            text: 'Suppliers Orders',
            active: true,
          },
        ],
      },
    },
    {
      path: '/bookkeeping',
      name: 'bookkeeping',
      component: () => import('@/views/controlling/Bookkeeping.vue'),
      meta: {
        pageTitle: 'Bookkeeping',
        breadcrumb: [
          {
            text: 'Bookkeeping',
            active: true,
          },
        ],
      },
    },
    {
      path: '/invoices-gmi',
      name: 'invoices-gmi',
      component: () => import('@/views/controlling/GMI.vue'),
      meta: {
        pageTitle: 'GMI',
        breadcrumb: [
          {
            text: 'GMI',
            active: true,
          },
        ],
      },
    },
    {
      path: '/plan-numbers',
      name: 'plan-numbers',
      component: () => import('@/views/controlling/PlanNumbers.vue'),
      meta: {
        pageTitle: 'Plan Numbers',
        breadcrumb: [
          {
            text: 'Plan Numbers',
            active: true,
          },
        ],
      },
    },
    {
      path: '/payment-settings',
      name: 'payment-settings',
      component: () => import('@/views/controlling/PaymentSettings.vue'),
      meta: {
        pageTitle: 'Payment Settings',
        breadcrumb: [
          {
            text: 'Payment Settings',
            active: true,
          },
        ],
      },
    },
    {
      path: '/performance-overview',
      name: 'performance-overview',
      component: () => import('@/views/controlling/PerformanceOverview.vue'),
      meta: {
        pageTitle: 'Performance Overview',
        breadcrumb: [
          {
            text: 'Performance Overview',
            active: true,
          },
        ],
      },
    },
    {
      path: '/vacation-sickday-overview',
      name: 'vacation-sickday-overview',
      component: () => import('@/views/controlling/VacationSickdayOverview.vue'),
      meta: {
        pageTitle: 'Vacation/Sickday Overview',
        breadcrumb: [
          {
            text: 'Vacation/Sickday Overview',
            active: true,
          },
        ],
      },
    },
    {
      path: '/config-ai',
      name: 'config-ai',
      component: () => import('@/views/controlling/ConfigAI.vue'),
      meta: {
        pageTitle: 'Config AI',
        breadcrumb: [
          {
            text: 'Config AI',
            active: true,
          },
        ],
      },
    },
    {
      path: '/user-management',
      name: 'user-management',
      component: () => import('@/views/users/UserManagement.vue'),
      meta: {
        pageTitle: 'User Management',
        breadcrumb: [
          {
            text: 'User Management',
            active: true,
          },
        ],
      },
    },
    {
      path: '/vacation-tracking',
      name: 'vacation-tracking',
      component: () => import('@/views/VacationTracking.vue'),
      meta: {
        pageTitle: 'Vacation Tracking',
        breadcrumb: [
          {
            text: 'Vacation Tracking',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/password-reset-done',
      name: 'password-reset-done',
      component: () => import('@/views/PasswordResetDone.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/password-reset',
      name: 'password-reset',
      component: () => import('@/views/PasswordReset.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/password-reset-complete',
      name: 'password-reset-complete',
      component: () => import('@/views/PasswordResetComplete.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
